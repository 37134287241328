.profile-section-personal-details-titles-wrapper {
  display: flex;
}
.profile-section-title {
  font-weight: 600;
  line-height: 30px;
}
.documents-section {
  /* border: 1px solid black; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.documents-section .profile-section-personal-details-titles-wrapper {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  width: 49%;
  min-height: 150px;
}
.rating-details-section .profile-section-personal-details-titles-wrapper{
 width: 33.33%; 
 display: flex;
 align-items: center;
}
.rating-details-section .profile-section-title{
  text-transform: capitalize;
  margin: 3px;
}
.profile-section-value {
  font-weight: 600;
  padding-left: 5px;
  line-height: 30px;
  color: rgba(151, 151, 151, 1);
}
.section-title-edit-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(207, 207, 207, 1);
  padding: 5px;
  margin: 15px auto;
  justify-content: space-between;
}
.section-title-container {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-edit-container {
  line-height: 28px;
  font-weight: 700;
  color: rgba(0, 179, 255, 1);
  cursor: pointer;
}
.get-payment-link-btn {
  color: #59d559;
  cursor: pointer;
  text-transform: capitalize;
  margin-left: 20px;
}
.edit-pannel-profile-section-wrapper {
  display: flex;
}

.edit-pannel-profile-section-wrapper .name-place-status-wrapper {
  /* border: 1px solid black; */
  width: 90%;
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.name-place-status-wrapper .fullname-title {
  /* font-family: Open Sans; */
  font-size: 20px;
  font-weight: 600;
  /* line-height: 35px; */
  /* text-align: left; */
  text-transform: capitalize;
}
.name-place-status-wrapper .place-gender-title {
  font-weight: 600;
  text-transform: capitalize;
  color: rgba(151, 151, 151, 1);
}
.active-status-title {
   border: 1px solid black; 
}
.active-status-value {
  margin: 0 20px;
}
.ant-rate {
  color: rgba(29, 161, 242, 1);
}
.approved-icon {
  position: relative;
  width: fit-content;
}
.showRequest--Edit-btn {
  width: 100%;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
.file-upload-btn {
  text-decoration: underline;
  color: rgb(145, 145, 145);
  cursor: pointer;
}
.file-upload-btn:hover {
  color: rgb(12, 12, 12);
}
.ant-modal-footer{
  display: flex;
  justify-content: flex-end;
}