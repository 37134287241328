.menu-bar{
    height: 90vh;
    /* margin-top: 2rem; */
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 1rem;
    /* border: 1px solid white; */
}
.toggle{
    color: white;
}
.toggle .ant-btn-icon{
   transform: scale(1.3);
}
.toggle:hover{
    color: white !important;
}

.sideNavToggle-wrapper{
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
}