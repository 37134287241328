.custometable-longcell-wrapper {
  width: 350px;
  overflow-x: overlay;
  scrollbar-color: rgba(0, 0, 0, 0.582) rgba(5, 5, 5, 0.06);
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}
.custometable-longcell-container {
}
.ant-table-cell{
  padding: 3px!important;
  line-height: 1.5;
  min-width: 100px;
  text-transform: capitalize;
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

*::-webkit-scrollbar-track {
  background-color: white !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c8cc !important;
  border-radius: 5px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #979899 !important;
}
