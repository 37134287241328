@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,600&display=swap");
* {
  margin: 0%;
  padding: 0%;
  font-family: Open Sans;
  text-decoration: none;
}


.siderbar{
  color: white;
  height: 100vh;
  position: relative;
}
.main-layout{
  /* border: 3px solid rgb(35, 221, 35); */
  overflow: overlay;
  height: 100vh;
  background-color: white;
}