.ant-drawer-content-wrapper {
  width: 90vw !important;
}

.ant-drawer-body {
  padding: 0;
  background-color: #f5f5f5;
}

/* Main Layout */
.student-drawer-content {
  display: flex;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.main-content {
  flex: 1;
  padding: 24px;
  background-color: #f9f9f9;
  border-radius: 8px 0 0 8px;
}

.side-panel {
  width: 300px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid #f0f0f0;
}

/* Tab Styles */
.ant-tabs-nav {
  margin-bottom: 24px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.ant-tabs-tab {
  font-size: 16px;
  transition: all 0.3s ease;
}

.ant-tabs-tab:hover {
  color: #1890ff;
}

.ant-tabs-tab-active {
  font-weight: 600;
}

/* Section Styles */
.profile-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.profile-section:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1890ff;
  display: flex;
  align-items: center;
}

.section-title::before {
  content: '\e64c';
  font-family: 'anticon';
  margin-right: 10px;
  font-size: 24px;
}

/* Profile Details Styles */
.profile-section-personal-details-titles-wrapper {
  display: flex;
  margin-bottom: 16px;
  align-items: center;
}

.profile-section-title {
  font-weight: 600;
  width: 150px;
  color: #555;
}

.profile-section-value {
  flex: 1;
  color: #333;
}

.section-edit-container {
  color: #1890ff;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.section-edit-container:hover {
  color: #40a9ff;
}

.section-edit-container::before {
  content: '\e692';
  font-family: 'anticon';
  margin-right: 5px;
}

/* Form Styles */
.ant-form-item-label > label {
  font-weight: 600;
  color: #333;
}

.ant-input,
.ant-select-selector {
  border-radius: 6px;
}

/* Requirements Section */
.requirements-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Payments Container */
.payments-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.ant-alert {
  margin-bottom: 24px;
}

.ant-table-wrapper {
  margin-top: 24px;
}

/* Tutors Tab Styles */
.tutors-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.tutors-container .ant-input-search {
  margin-bottom: 24px;
}

.filter-form {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 24px;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.filter-actions .ant-btn {
  margin-left: 12px;
}

.applied-filters {
  margin-top: 20px;
  margin-bottom: 24px;
}

.applied-filters .ant-tag {
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px 8px;
  border-radius: 4px;
}

.tutor-results {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

/* Button Styles */
.ant-btn {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

/* Escalation Section Styles */
.escalation-section {
  margin-bottom: 24px;
}

.escalation-section h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.escalation-section .ant-space {
  display: flex;
  align-items: center;
}

.escalation-section .ant-switch {
  margin-right: 12px;
}

.escalation-section .ant-select {
  width: 150px;
  margin-right: 12px;
}

.escalation-section .ant-btn {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: white;
}

.escalation-section .ant-btn:hover {
  background-color: #ff7875;
  border-color: #ff7875;
}

/* Activity Section Styles */
.activity-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 16px;
}

.activity-section h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.activity-section .ant-tabs-nav {
  margin-bottom: 16px;
}

.activity-section .ant-tabs-tab {
  padding: 8px 16px;
}

.activity-section .ant-card {
  margin-bottom: 16px;
}

.activity-section .ant-card-head {
  background-color: #f0f0f0;
  padding: 8px 16px;
}

.activity-section .ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

.activity-section .ant-card-extra {
  font-size: 12px;
  color: #888;
}

.activity-section .ant-card-body {
  padding: 16px;
}

.activity-section .ant-input {
  margin-bottom: 16px;
}

.activity-section .ant-btn {
  width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .student-drawer-content {
    flex-direction: column;
  }

  .main-content {
    border-radius: 8px 8px 0 0;
    padding-right: 24px;
    border-right: none;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 24px;
  }

  .side-panel {
    width: 100%;
    padding: 24px;
    border-radius: 0 0 8px 8px;
    border-left: none;
    border-top: 1px solid #f0f0f0;
  }

  .escalation-section,
  .activity-section {
    max-width: 600px;
    margin: 0 auto 24px;
  }
}

@media (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 100vw !important;
  }

  .requirements-section {
    grid-template-columns: 1fr;
  }
  
  .filter-actions {
    flex-direction: column;
  }
  
  .filter-actions .ant-btn {
    margin-left: 0;
    margin-top: 12px;
    width: 100%;
  }
}

.hidden {
  display: none;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.profile-section,
.tutors-container,
.payments-container,
.escalation-section,
.activity-section {
  animation: fadeIn 0.5s ease-out;
}