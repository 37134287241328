.flash-cards-container {
    padding: 20px;
    background-color: #f0f2f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  
  .controls-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle {
    margin-right: 20px;
  }
  
  .date-controls {
    display: flex;
    align-items: center;
  }

  .city-controls {
    display: flex;
    align-items: center;
    margin-left: 2%;
  }
  
  .date-range-picker {
    min-width: 300px;
    margin-left: 10px;
  }
  
  .preset-range-select {
    width: 120px;
    margin-left: 10px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 20px;
  }
  
  .card {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .card-title {
    display: flex;
    align-items: center;
  }
  
  .card-icon {
    color: white;
    font-size: 24px;
    margin-right: 8px;
  }
  
  .card-value {
    color: white;
    font-size: 24px;
  }
  
  .card-link {
    color: white;
  }
  
  .card-content {
    position: relative;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }
  
  .card-details {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
  
  .card-details-number {
    color: white;
    font-size: 120px;
    margin: 0 0 -30px 0;
  }

  .city-select {
    min-width: 200px;
    width: 30vw;
    max-width: 500px;
  }